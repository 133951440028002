import "./App.css";
import Routing from "./Routing";
import { CpoProductsProvider } from "./contexts/CpoProductsContext";

function App() {
  return (
    <div>
      <CpoProductsProvider>
        <Routing />
      </CpoProductsProvider>
    </div>
  );
}

export default App;
