import React, { useState, useEffect } from "react";
import { Button, Header, Modal, Icon, Table } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { getOfflineReasons } from "../../helper/api";

function SettingModal() {
  const [open, setOpen] = React.useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [reasons, setReasons] = useState([]);
  const [activeRow, setActiveRow] = useState();

  useEffect(() => {
    setReasons(getOfflineReasons().sort());
  }, []);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Icon name="setting" />}
    >
      <Modal.Header>Offline reasons</Modal.Header>
      <Modal.Content scrolling>
        <Table singleLine>
          <Table.Body>
            {reasons.map((reason, index) => (
              <Table.Row
                key={index}
                active={index === activeRow}
                onClick={() => {
                  setActiveRow(index);
                }}
                style={{ maxWidth: "100%" }}
              >
                <Table.Cell>{reason}</Table.Cell>
                <Table.Cell
                  selectable
                  style={
                    isDesktopOrLaptop
                      ? { maxWidth: "230px" }
                      : { maxWidth: "170px" }
                  }
                ></Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Done"
          labelPosition="right"
          onClick={() => setOpen(false)}
          // positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default SettingModal;
