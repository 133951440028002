import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavMenu from "../NavMenu";
import {
  Header,
  Grid,
  Tab,
  Container,
  Segment,
  Modal,
  Icon,
} from "semantic-ui-react";
import ExtendedSessions from "./ExtendedSessions";

function ExtendedTransactionsMain(props) {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [lastRequestedTime, setLastRequestedTime] = useState("");

  const handleCallbackData = (requestedTime) => {
    setLastRequestedTime(requestedTime);
  };

  const panes = [
    {
      menuItem: "Ongoing sessions",
      render: () => (
        <Tab.Pane>
          <ExtendedSessions callback={handleCallbackData} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <NavMenu>
      <Container style={{ marginTop: "20px" }}>
        <Segment raised>
          <Grid container stackable>
            <Grid.Row centered>
              <Grid.Column>
                <Header
                  as="h4"
                  content="Extended Transaction List"
                  subheader={`Last update: ${lastRequestedTime}`}
                />
                <Segment>
                  <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={panes}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Modal
          basic
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size="small"
        >
          <Header icon>
            <Icon name="sign-out" />
            401 Unauthorized Access, redirecting you to login page
          </Header>
        </Modal>
      </Container>
    </NavMenu>
  );
}

export default ExtendedTransactionsMain;
