import React, { useEffect, useState, Fragment } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import moment from "moment";
import { connect } from "react-redux";
import {
  Table,
  Header,
  Placeholder,
  Pagination,
  Dropdown,
  Grid,
  Button,
  Message,
} from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { getExtendedTransactions } from "../../helper/api";

function ExtendedSessions(props) {
  const [connection, setConnection] = useState();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [rows, setRows] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [boundaryRange, setBoundaryRange] = useState(isDesktopOrLaptop ? 1 : 0);
  const [siblingRange, setSiblingRange] = useState(isDesktopOrLaptop ? 1 : 0);
  const [activePageSize, setActivePageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(10);
  const [selectedItem, setSelectedItem] = useState("All");
  const [data, setData] = useState([]);
  const [activeRow, setActiveRow] = useState();
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [messageHeader, setMessageHeader] = useState("");
  const [messageItem, setMessageItem] = useState("");

  const options = [
    { key: "critical", text: "Critical", value: "Critical" },
    { key: "warning", text: "Warning", value: "Warning" },
    { key: "all", text: "All", value: "All" },
  ];

  const handlePaginationChange = (e, { activePage }) =>
    setActivePage(activePage);

  useEffect(() => {
    const conn = new HubConnectionBuilder()
      .withUrl("https://cegapi.azure-api.net/chargerhub", {
        headers: {
          "x-api-key": process.env.REACT_APP_SIGNALR_KEY,
        },
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Trace)
      .build();

    setConnection(conn);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("SignalR connection established for Ongoing Sessions.");
          setisLoading(true);
        })
        .catch((error) => {
          console.log("Error establishing SignalR connection: ", error);
        });

      connection.on("OngoingSessions", (data) => {
        console.log("OngoingSessions: ", data);
        props.callback(
          moment(data.lastRequestedTime).format("yyyy-MM-DD HH:mm:ss")
        );
        setData(data.ongoingTransactions);
        const num = Math.ceil(
          data.ongoingTransactions?.length / activePageSize
        );
        setTotalPages(num);

        if (data.remarks) {
          setMessageHeader(data.remarks);
          setData([]);
          setTotalPages(0);
          setDisplaySuccess(true);
          setisLoading(false);
        }
      });
    }

    return () => {
      if (connection) {
        connection
          .stop()
          .then(() => {
            console.log("SignalR connection closed.");
          })
          .catch((error) => {
            console.log("Error establishing SignalR connection: ", error);
          });
      }
    };
  }, [connection]);

  useEffect(() => {
    props.getExtendedTransactions().catch(function (error) {
      console.log(error.response);
    });
  }, [isLoading]);

  useEffect(() => {
    if (data != null) {
      setRows(
        data.slice(
          (activePage - 1) * activePageSize,
          activePage * activePageSize
        )
      );
    }

    setisLoading(false);
  }, [data, activePage]);

  useEffect(() => {
    if (displaySuccess) {
      setTimeout(() => setDisplaySuccess(false), 30000);
    }
  }, [displaySuccess]);

  const handleDropdownChange = (input) => {
    // To update
    const value = input.target.innerText;
    setSelectedItem(value);

    let filteredList;

    if (value === "Warning") {
      filteredList = data.filter((x) => x.warning === "WARNING");
    } else if (value === "Critical") {
      filteredList = data.filter((x) => x.warning === "CRITICAL");
    } else {
      filteredList = data;
    }

    setRows(
      filteredList.slice(
        (activePage - 1) * activePageSize,
        activePage * activePageSize
      )
    );

    const num = Math.ceil(filteredList.length / activePageSize);
    setTotalPages(num);
  };

  const handleUpdate = () => {
    setisLoading(true);

    props
      .getExtendedTransactions()
      .then(function (response) {
        console.log("response", response);

        if (response.data.message) {
          setMessageHeader(response.data.message);
          setDisplaySuccess(true);
          setisLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  return (
    <Fragment>
      <Grid columns={2}>
        <Grid.Column
          style={
            isDesktopOrLaptop
              ? { marginTop: "10px" }
              : {
                  marginTop: "10px",
                  width: "265px",
                }
          }
        >
          <Dropdown
            text={selectedItem}
            options={options}
            onChange={handleDropdownChange}
            value={selectedItem}
          />
        </Grid.Column>
        <Grid.Column
          style={
            isDesktopOrLaptop
              ? {
                  boxShadow: "none",
                  marginTop: "5px",
                  textAlign: "center",
                }
              : {
                  boxShadow: "none",
                  marginTop: "10px",
                  width: "265px",
                }
          }
        >
          <Button basic size="small" onClick={handleUpdate} color="red">
            Update
          </Button>
        </Grid.Column>
      </Grid>
      {isLoading ? (
        <Placeholder style={{ marginTop: "50px" }}>
          <Placeholder.Header>
            <Placeholder.Line length="medium" />
          </Placeholder.Header>
        </Placeholder>
      ) : (
        <Header
          as="h5"
          content={`${rows.length} Number of extended sessions`}
        />
      )}
      {displaySuccess && (
        <Message success={displaySuccess}>
          <Message.Content>
            <Message.Header>{messageHeader}</Message.Header>
            {messageItem}
          </Message.Content>
        </Message>
      )}
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>EVSE Id</Table.HeaderCell>
            <Table.HeaderCell>Socket Type</Table.HeaderCell>
            <Table.HeaderCell>Site Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>
            <Table.HeaderCell>Duration</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map((row, index) => (
            <Table.Row
              key={index}
              active={index === activeRow}
              onClick={() => {
                setActiveRow(index);
              }}
            >
              <Table.Cell>{row.evseId}</Table.Cell>
              <Table.Cell>{row.socketType}</Table.Cell>
              <Table.Cell style={{ whiteSpace: "pre-wrap" }}>
                {row.locationName}
              </Table.Cell>
              <Table.Cell>{row.warning}</Table.Cell>
              <Table.Cell>
                {moment(row.startTime).format("yyyy-MM-DD HH:mm:ss")}
              </Table.Cell>
              <Table.Cell>{row.parsedDuration}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Pagination
        style={{ height: "40px", marginTop: "20px" }}
        activePage={activePage}
        boundaryRange={boundaryRange}
        onPageChange={handlePaginationChange}
        size="mini"
        siblingRange={siblingRange}
        totalPages={totalPages}
      />
    </Fragment>
  );
}

export default connect(null, {
  getExtendedTransactions,
})(ExtendedSessions);
