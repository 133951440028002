import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardRoute from "../contexts/DashboardRoute";
import TechnologyRoute from "../contexts/TechnologyRoute";
import Login from "../Login";
import OfflineStations from "../OfflineStations";
import Home from "../Home";
import Technology from "../Technology";
import CallCentreForm from "../CallCentreForm";
import Stations from "../Stations";
import ExtendedTransactionsMain from "../ExtendedTransactions";
import ResetPassword from "../ResetPassword";

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route exact path="/" element={<DashboardRoute />}>
        <Route path="/offlinestations" element={<OfflineStations />} />
      </Route>
      <Route exact path="/" element={<DashboardRoute />}>
        <Route path="/home" element={<Home />} />
      </Route>
      <Route exact path="/" element={<TechnologyRoute />}>
        <Route path="/stations" element={<Stations />} />
      </Route>
      <Route exact path="/" element={<TechnologyRoute />}>
        <Route path="/technology" element={<Technology />} />
      </Route>
      {/* <Route exact path="/" element={<DashboardRoute />}>
        <Route path="/callcentreform" element={<CallCentreForm />} />
      </Route> */}
      <Route exact path="/resetpassword" element={<ResetPassword />} />
      <Route exact path="/" element={<DashboardRoute />}>
        <Route
          path="/extendedsessions"
          element={<ExtendedTransactionsMain />}
        />
      </Route>
    </Routes>
  );
}
